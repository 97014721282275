/* You can add any global styles here */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&family=Roboto:wght@300;400;700&display=swap');

:root {
  --color-primary: #1a2b3c;
  --color-background: #f5f7fa;
  --color-white: #ffffff;
  --color-accent: #ffd700;
  --color-secondary: #20b2aa;
}

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  background-color: var(--color-background);
  color: var(--color-primary);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Montserrat', sans-serif;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

/* Add more global styles as needed */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}